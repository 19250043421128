var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('i-table',{attrs:{"title":_vm.$tc('equipment', 2),"headers":_vm.headers,"otherPermission":_vm.equipementBtn.dial,"app":"equipements.equipement","api":"equipment","otherBtn":_vm.equipementBtn.maintenance},on:{"click:create":function($event){return _vm.changeRoute({ pk: 'create' })},"click:edit":_vm.changeRoute},scopedSlots:_vm._u([{key:"otherBtn",fn:function({ item }){return [_c('i-btn',{attrs:{"title":_vm.$tc('equipment_maintenance', 1),"outlined":"","classes":"mx-1","icon":"fa-calendar","color":"secondary"},on:{"click":function($event){return _vm.changeRouteMaintenance(item)}}})]}},{key:"item.description",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.mode",fn:function({ item }){return [_c('span',{domProps:{"textContent":_vm._s(
          item.mode === 'buy'
            ? _vm.$t('buy')
            : item.mode === 'rent'
            ? _vm.$t('rent')
            : ''
        )}})]}},{key:"item.bought_date_and_rent_date",fn:function({ item }){return [_c('span',{domProps:{"textContent":_vm._s(
          item.rent_start_date != null && item.rent_end_date != null
            ? _vm.dateFormat(item.rent_start_date) +
              ' / ' +
              _vm.dateFormat(item.rent_end_date)
            : _vm.dateFormat(item.bought_date)
        )}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }